import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, AfterViewInit, OnChanges } from '@angular/core';
import { UntypedFormGroup , UntypedFormBuilder , Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { TranslatorService } from '../../../core/translator/translator.service';
import { Subscription } from 'rxjs';
import { PaymentMethodType } from '../../../core/classes/interfaces.interface';

@Component({
  selector: 'app-stripe-element',
  templateUrl: './stripe-element.component.html',
  styleUrls: ['./stripe-element.component.scss']
})
export class StripeElementComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Input() paymentData: any;
  @Input() configuration: any;
  @Input() public_key: string;
  @Input() mode: string;
  @Input() availablePaymentMethods = [PaymentMethodType.CARD, PaymentMethodType.SEPA_DEBIT, PaymentMethodType.PAYPAL, PaymentMethodType.KLARNA];

  @Output() click = new EventEmitter;
  @Output() onComplete = new EventEmitter;
  @Output() onError = new EventEmitter;
  @Output() onValidationChange = new EventEmitter;

  elements: any;
  stripe: any;
  cardElement: any;
  sepaElement: any;
  paymentElement: any;
  PaymentMethodType = PaymentMethodType;

  stripeForm: UntypedFormGroup;
  cardElementValidation: any;
  sepaElementValidation: any;
  paymentElementValidation: any;

  amount: number;
  currency: string;
  beneficiary: string;
  saveMethodAvailable: boolean;

  private languageSub: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private translator: TranslatorService
  ) {
    this.initValidation();
  }

  ngOnInit() {
    if( !this.configuration ) {
      this.init()
    }

    this.languageSub = this.translator.onLngChange.subscribe((lng) => {
      if (lng) {
        //this.createStripeElements(lng);
        this.createStripePaymentElement(lng);
      }
    });
  }
  
  ngOnDestroy() {
    this.languageSub.unsubscribe();
  }

  ngAfterViewInit() {
    if( this.paymentData ) {
      // console.log( '@stripeElement - paymentData' , this.paymentData )
      if (!this.paymentData.sepa) {
        this.availablePaymentMethods = this.availablePaymentMethods.filter(availablePaymentMethod =>
          availablePaymentMethod != PaymentMethodType.SEPA_DEBIT);
      }
      if (!this.paymentData.paypal) {
        this.availablePaymentMethods = this.availablePaymentMethods.filter(availablePaymentMethod =>
          availablePaymentMethod != PaymentMethodType.PAYPAL);
      }
      if (!this.paymentData.klarna) {
        this.availablePaymentMethods = this.availablePaymentMethods.filter(availablePaymentMethod =>
          availablePaymentMethod != PaymentMethodType.KLARNA);
      }
      this.amount = this.paymentData.amount || 0;
      this.currency = this.paymentData.currency ? this.paymentData.currency.toLowerCase() || 'eur' : 'eur';
      if (!this.paymentData.saveCustomerPMData) {
        let fullName = "";
        if (this.paymentData.customerFullName) {
          fullName = this.paymentData.customerFullName;
        } else if (this.paymentData.customerName && this.paymentData.customerSurname) {
          fullName = this.paymentData.customerName + " " + this.paymentData.customerSurname;
        }
        this.stripeForm.controls['name'].setValue(fullName);
        this.stripeForm.controls['email'].setValue(this.paymentData.customerEmail);
      }
      this.beneficiary = this.paymentData.beneficiary || this.paymentData.nameMerchant || this.paymentData.nameExternalApp;
      this.saveMethodAvailable = this.paymentData.offSessionButton;
    }
  }

  ngOnChanges() {
    // console.log('stripe-element-component ngOnChanges')

    if( this.mode == 'pay' || !this.mode ) {
      this.init();
      this.load( this.configuration )
    } else if( this.mode == 'manage' ) {
      this.init();
      this.load( null , null , environment.stripe_public_key );
    }
    
  }

  get language() {
    return this.translator.getLanguage();
  }

  initValidation() {
    this.cardElementValidation = {
      complete: false,
      error: false
    };
    this.sepaElementValidation = {
      complete: false,
      error: false
    };
    this.paymentElementValidation = {
      complete: false,
      error: false
    };
  }

  init() {
    this.stripeForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      paymentMethodType: [null, [Validators.required]],
      saveMethod: [false]
    });
    this.stripeForm.valueChanges.subscribe(result => {
      this.checkValidation()
    });
  }

  createStripeElements(lng) {
    if (this.stripe) {
      this.elements = this.stripe.elements({locale: lng});
      if (this.cardElement) {
        this.cardElement.destroy();
      }
      this.cardElement = this.elements.create('card',  {
        style: {
          base: {
            iconColor: '#666EE8',
            color: '#31325F',
            fontWeight: 300,
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSize: '16px',
            '::placeholder': {
              color: '#CFD7E0'
            }
          }
        },
        hidePostalCode: true
      });
      this.cardElement.mount('#card-element');

      if (this.sepaElement) {
        this.sepaElement.destroy();
      }
      this.sepaElement = this.elements.create('iban',  {
        style: {
          base: {
            iconColor: '#666EE8',
            color: '#31325F',
            fontWeight: 300,
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSize: '16px',
            '::placeholder': {
              color: '#CFD7E0'
            }
          }
        },
        supportedCountries: ['SEPA'],
        placeholderCountry: lng
      });
      this.sepaElement.mount('#sepa-element');
    }

    this.initValidation();

    this.cardElement.on('change', (event) => {
      if( event.complete ) {
        this.cardElementValidation.complete = true;
        this.cardElementValidation.error = false;
      } else {
        this.cardElementValidation.complete = false;
        this.cardElementValidation.error = true;
      }
      this.checkValidation()
    })

    this.sepaElement.on('change', (event) => {
      if( event.complete ) {
        this.sepaElementValidation.complete = true;
        this.sepaElementValidation.error = false;
      } else {
        this.sepaElementValidation.complete = false;
        this.sepaElementValidation.error = true;
      }
      this.checkValidation()
    })
  }

  createStripePaymentElement(lng) {
    if (this.stripe) {
      let paymentMethodTypes = [ 'card' ];
      let paymentMethodOrder = [ 'card', 'apple_pay', 'google_pay' ];
      let paymentMethodTypeTerms = [ 'card', 'applePay', 'googlePay' ];
      if (this.availablePaymentMethods.includes(PaymentMethodType.SEPA_DEBIT)) {
        paymentMethodTypes = [ ...paymentMethodTypes, 'sepa_debit' ];
        paymentMethodOrder = [ ...paymentMethodOrder, 'sepa_debit' ];
        paymentMethodTypeTerms = [ ...paymentMethodTypeTerms, 'sepaDebit' ];
      }
      if (this.availablePaymentMethods.includes(PaymentMethodType.PAYPAL)) {
        paymentMethodTypes = [ ...paymentMethodTypes, 'paypal' ];
        paymentMethodOrder = [ ...paymentMethodOrder, 'paypal' ];
        paymentMethodTypeTerms = [ ...paymentMethodTypeTerms, 'paypal' ];
      }
      if (this.availablePaymentMethods.includes(PaymentMethodType.KLARNA)) {
        paymentMethodTypes = [ ...paymentMethodTypes, 'klarna' ];
        paymentMethodOrder = [ ...paymentMethodOrder, 'klarna' ];
        paymentMethodTypeTerms = [ ...paymentMethodTypeTerms, 'klarna' ];
      }
      const appearance = {
        theme: 'stripe',
        variables: {
          fontFamily: '"Source Sans Pro", sans-serif',
          fontSizeBase: '14px',
          borderRadius: '.25rem',
        },
        rules: {
          '.Tab': {
            color: '#495057',
            lineHeight: '1.52857143',
            padding: '.375rem 1rem',
            border: '1px solid #dde6e9',
            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
            boxShadow: 'none'
          },
          '.Tab--selected, .Tab--selected:focus': {
            borderColor: '#66afe9',
            boxShadow: 'none'
          },
          '.Label': {
            color: '#656565'
          },
          '.Input': {
            color: '#495057',
            lineHeight: '1.52857143',
            padding: '.375rem 1rem',
            border: '1px solid #dde6e9',
            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
            boxShadow: 'none'
          },
          '.Input:focus': {
            borderColor: '#66afe9',
            boxShadow: 'none'
          },
          '.Input::placeholder': {
            color: '#CFD7E0'
          }
        }
      };

      if (this.mode == 'pay' || !this.mode) {
        this.elements = this.stripe?.elements({
          locale: lng,
          mode: 'payment',
          amount: this.amount,
          currency: this.currency,
          paymentMethodTypes,
          paymentMethodCreation: 'manual',
          appearance
        });
      } else if (this.mode == 'manage') {
        this.elements = this.stripe?.elements({
          locale: lng,
          mode: 'setup',
          currency: this.currency,
          paymentMethodTypes,
          paymentMethodCreation: 'manual',
          appearance
        });
      }

      if (this.paymentElement) {
        this.paymentElement.destroy();
      }
      this.paymentElement = this.elements.create('payment', {
        layout: {
          type: 'tabs',
          defaultCollapsed: false
        },
        paymentMethodOrder,
        fields: {
          billingDetails: 'never'
        },
        terms: { ...paymentMethodTypeTerms.reduce((map, paymentMethodTypeTerm) => {
            map[paymentMethodTypeTerm] = 'never';
            return map;
          }, {})
        }
      });
      this.paymentElement.mount('#payment-element');

      this.initValidation();

      this.paymentElement.on('change', (event) => {
        this.stripeForm.controls['paymentMethodType'].setValue(event.value ? event.value.type : null);

        if (event.complete) {
          this.paymentElementValidation.complete = true;
          this.paymentElementValidation.error = false;
        } else {
          this.paymentElementValidation.complete = false;
          this.paymentElementValidation.error = true;
        }
        this.checkValidation()
      })
    }
  }

  load(configuration = null, data = null , p_key: string = environment.stripe_public_key) {
    // console.log('configuration inside component' , configuration)

    if( configuration ) {
      this.stripe = new Stripe(environment.stripe_public_key, configuration.public_key);
    } else if( p_key) {
      this.stripe = new Stripe(environment.stripe_public_key)
    }

    if( this.paymentData ) {
      if (!this.paymentData.sepa) {
        this.availablePaymentMethods = this.availablePaymentMethods.filter(availablePaymentMethod =>
          availablePaymentMethod != PaymentMethodType.SEPA_DEBIT);
      }
      if (!this.paymentData.paypal) {
        this.availablePaymentMethods = this.availablePaymentMethods.filter(availablePaymentMethod =>
          availablePaymentMethod != PaymentMethodType.PAYPAL);
      }
      if (!this.paymentData.klarna) {
        this.availablePaymentMethods = this.availablePaymentMethods.filter(availablePaymentMethod =>
          availablePaymentMethod != PaymentMethodType.KLARNA);
      }
      this.amount = this.paymentData.amount || 0;
      this.currency = this.paymentData.currency ? this.paymentData.currency.toLowerCase() || 'eur' : 'eur';
    }
    
    //this.createStripeElements(localStorage.getItem( 'langPreference' ));
    this.createStripePaymentElement(localStorage.getItem( 'langPreference' ));

    if( this.paymentData ) {
      // console.log( '@stripeElement - paymentData' , this.paymentData )
      if (!this.paymentData.saveCustomerPMData) {
        let fullName = "";
        if (this.paymentData.customerFullName) {
          fullName = this.paymentData.customerFullName;
        } else if (this.paymentData.customerName && this.paymentData.customerSurname) {
          fullName = this.paymentData.customerName + " " + this.paymentData.customerSurname;
        }
        this.stripeForm.controls['name'].setValue(fullName);
        this.stripeForm.controls['email'].setValue(this.paymentData.customerEmail || "");
      }
    }
    
  }

  emitOnSubmit() {
    this.click.emit( this.stripeForm.value );
  }

  getStripeElementOld() {
    if( this.cardElement || this.sepaElement )
      return {"cardElement": this.cardElement , "sepaElement": this.sepaElement , "elements": this.elements , "stripe": this.stripe , "stripeForm": this.stripeForm};
    else 
      return null;
  }

  getStripeElement() {
    if( this.paymentElement )
      return {"paymentElement": this.paymentElement , "elements": this.elements , "stripe": this.stripe , "stripeForm": this.stripeForm};
    else 
      return null;
  }

  checkValidationOld() {
    if( ( ( this.stripeForm.controls['paymentMethodType'].value == PaymentMethodType.CARD && this.cardElementValidation.complete ) ||
      ( this.stripeForm.controls['paymentMethodType'].value == PaymentMethodType.SEPA_DEBIT && this.sepaElementValidation.complete ) ) && this.stripeForm.valid ) {
      this.onValidationChange.emit('valid');
      this.onComplete.emit();
    } else {
      this.onValidationChange.emit('invalid');
      this.onError.emit();
    }
  }

  checkValidation() {
    if (this.stripeForm.valid && this.paymentElementValidation.complete) {
      this.onValidationChange.emit('valid');
      this.onComplete.emit();
    } else {
      this.onValidationChange.emit('invalid');
      this.onError.emit();
    }
  }

}
