// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  restBaseUrl: 'https://testapi.servicepay.it/servicepay-gateway-rest/rest',
  apiBaseUrl: 'https://testapi.servicepay.it/servicepay-gateway-rest/',
  rows: 5,
  loggedInDays: 5,
  version:'1.2.0T',
  stripe_public_key: 'pk_test_GnAiuP9K8p5zB2LM5642y1Y0',
  domain: 'testgateway.servicepay.it',
  cookieExpiryDays: 30
};
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
