<ul class="sidebar-nav">
  <ul class="list-group">
    <!-- <div> <a href="#side-menu-toggle" id="side-menu-toggle">
        <i class="fa fa-times close-menu" aria-hidden="true"></i>
      </a></div> -->
    <li  *ngIf="userSelected" class=" sidebar-brandlist-group-item padding-top">{{'dashboard.navigate' | translate}}</li>
    <li class="list-group-item admin" *ngIf="userSelectedExist()">
      <div class="row" >
        <div class="user-img">
            <p *ngIf="userSelected.companyName != null && userSelected.companyName.length > 0" class="capitals-side">{{userSelected.companyName.charAt(0)}}</p>
            <p *ngIf="userSelected.companyName == null || userSelected.companyName.length == 0" class="capitals-side">U</p>
        </div>

        <div class="user-name">
          <div class="user-role-position">
            <span class="user-role">{{userSelected.companyName}}</span>
            <!-- <p class="usr-name">{{userSelected.username}}</p> -->
            <p class="usr-name">{{userSelected.pecAddress}}</p>
            <span class="clearSelectedUserIcon" (click)="clearSelectedUser()"> <i class="fa fa-times" aria-hidden="true"></i>
            </span>
          </div>
        </div>
      </div>
    </li>
    <li class="list-group-item search-i">
      <span class="others-label text-padding">{{'dashboard.others' | translate}}</span><br>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
        </div>
        <input (keyup)="searchUser($event) " type="text" class="form-control" placeholder="Cerca per nome" aria-label="Username"
          aria-describedby="basic-addon1">
      </div>
    </li>
    <span *ngFor="let user of users">
      <li *ngIf="userSelected == null || user.companyName != userSelected.companyName" class="list-group-item user" (click)="selectUser(user)">
        <div class="row">
          <div class="user-img">
              <p *ngIf="user.companyName != null && user.companyName.length > 0" class="capitals-side">{{user.companyName.charAt(0)}}</p>
              <p *ngIf="user.companyName == null || user.companyName.length == 0" class="capitals-side">U</p>
              <!-- <div class="certified-merchant confirmed" *ngIf="user.statusTos">
                <em class="fa-2x icon-check mr-2"></em>
              </div>
              <div class="certified-merchant unconfirmed" *ngIf="!user.statusTos">
                <em class="fa-2x icon-ban mr-2"></em>
              </div> -->
          </div>
          <div class="user-name">
            <div class="user-role-position">
              <p class="user-role">{{user.companyName}}</p>
              <p class="usr-name">{{user.pecAddress}}</p>
            </div>
          </div>
        </div>
      </li>
    </span>
  </ul>
</ul>
